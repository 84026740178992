import React from 'react';
import { graphql } from 'gatsby';
import styled from 'styled-components';

import ReactMarkdown from 'react-markdown';
import rehypeRaw from 'rehype-raw';

import { convertToBgImage, BgImage } from 'gbimage-bridge';
import BackgroundImage from 'gatsby-background-image';
import { GatsbyImage, getImage } from 'gatsby-plugin-image';
import { RiFacebookCircleFill } from 'react-icons/ri';

import { AiFillTwitterCircle } from 'react-icons/ai';
import { FacebookShareButton, TwitterShareButton } from 'react-share';

import MultiArticle from '../components/Articles/MultiArticle';
import SEO from '../components/SEO/SEO';

const Wrapper = styled.div`
  margin: 0px auto;
  max-width: 1800px;
`;

const Title = styled.div`
  h1 {
    color: white;
  }

  .bg-image-banner {
    display: flex;
    justify-content: center;
    align-items: center;
    text-align: center;
  }
`;

const ShareSection = styled.div`
  display: flex;
  justify-content: center;
  margin-top: 40px;
  .text {
    font-family: Oswald;
    color: black;
    padding-right: 15px;
  }
  .socials {
    display: flex;
    color: var(--darkgreen);
    margin-top: -6px;
  }
  .icon {
    padding: 0px 5px;
    font-size: 35px;
    &:hover {
      color: var(--lightgreen);
    }
  }
`;

const Content = styled.div`
  color: black;
  padding: 20px 20px;
  margin: 10px 0px;
  background-color: white;
  h2 {
    font-style: normal;
    font-weight: 500;
    line-height: 100%;
  }

  h3 {
    font-family: Oswald;
    color: var(--darkgreen);
  }
  img {
    width: 100%;
    height: auto;
  }
`;

const RelatedArticles = styled.div`
  padding: 40px 0;
  background-color: white;
  h2 {
    margin-bottom: 40px;
    font-family: 'Oswald';
    font-style: normal;
    font-weight: 500;
    font-size: 36px;
    line-height: 130%;
    text-align: center;
    text-transform: uppercase;
    color: #2d2b2c;
  }
`;

export default function Article({ data, location }) {
  const article = data.strapiArticles;
  const image = getImage(article.BannerImage.localFile.childImageSharp);
  const bgImage = convertToBgImage(image);

  const multi_article_content = {
    leftarticle:
      data.allStrapiArticles.edges[data.allStrapiArticles.edges.length - 1]
        .node,
    rightarticle:
      data.allStrapiArticles.edges[data.allStrapiArticles.edges.length - 2]
        .node,
  };

  const schema = {
    '@context': 'https://schema.org',
    '@type': 'NewsArticle',
    mainEntityOfPage: {
      '@type': 'WebPage',
      '@id': 'https://thehousecannabis.ca/blog',
    },
    headline: article.Title,
    datePublished: article.PublishedDate,
    image: [article.BannerImage.url],
  };

  return (
    <>
      <SEO
        title={article.Title}
        description={article.Preview_Text}
        schemaMarkup={schema}
      />
      <Wrapper>
        <div className="container-fluid">
          <div className="row">
            <div className="col-12 p-0">
              <Title>
                <BackgroundImage
                  Tag="div"
                  {...bgImage}
                  preserveStackingContext
                  className="bg-image-banner"
                  style={{
                    width: '100%',
                    height: '50vh',
                  }}
                >
                  <h1>{article.Title}</h1>
                </BackgroundImage>
              </Title>
            </div>
          </div>
          <div className="row">
            <div className="col-lg-4 col-12 order-lg-1 order-2">
              <ShareSection>
                <div className="text">SHARE ARTICLE</div>
                <div className="socials">
                  <div className="icon">
                    <FacebookShareButton url={location.href}>
                      <RiFacebookCircleFill />
                    </FacebookShareButton>
                  </div>
                  <div className="icon">
                    <TwitterShareButton url={location.href}>
                      <AiFillTwitterCircle />
                    </TwitterShareButton>
                  </div>
                </div>
              </ShareSection>
            </div>
            <div className="col-lg-8 col-12 order-lg-2 order-1">
              <Content>
                <ReactMarkdown
                  rehypePlugins={[rehypeRaw]}
                  children={article.Content.RichText_Content}
                />
              </Content>
            </div>
          </div>
        </div>
        <RelatedArticles>
          <h2>RELATED ARTICLES</h2>
          <MultiArticle
            leftarticle={multi_article_content.leftarticle}
            rightarticle={multi_article_content.rightarticle}
          />
        </RelatedArticles>
      </Wrapper>
    </>
  );
}

export const query = graphql`
  query ArticleQuery($slug: String!) {
    strapiArticles(slug: { eq: $slug }) {
      BannerImage {
        url
        localFile {
          childImageSharp {
            gatsbyImageData(quality: 100, width: 1900)
          }
        }
      }
      Content {
        RichText_Content
      }
      Title
      PublishedDate
    }

    allStrapiArticles {
      edges {
        node {
          id
          Title
          slug
          Preview_Text
          BannerImage {
            localFile {
              childImageSharp {
                gatsbyImageData
              }
            }
          }
        }
      }
    }
  }
`;
