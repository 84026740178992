import React from 'react';
import styled from 'styled-components';
import { Link } from 'gatsby';
import { BgImage } from 'gbimage-bridge';

const MOBILE_BREAKPOINT = '768px';

const Wrapper = styled.div`
  .viewAllBtn-mobile {
    margin-top: 30px;
    width: 100%;
    display: none;
    @media (max-width: ${MOBILE_BREAKPOINT}) {
      display: block;
      margin-top: 10px;
    }
  }

  height: 100%;

  margin: 70px 0px;
`;

const LeftArticle = styled.div`
  height: 50vh;
  display: flex;
  flex-direction: column;
  .viewAllBtn-desktop {
    margin-top: 30px;
    width: 100%;
    @media (max-width: ${MOBILE_BREAKPOINT}) {
      display: none;
    }
  }
  @media (max-width: ${MOBILE_BREAKPOINT}) {
    margin: 10px 0px;
  }
`;

const RightArticle = styled.div`
  display: flex;
  flex-direction: column;
  background: linear-gradient(287.58deg, #a1c654 20.01%, #b0e640 91.64%);
  height: 100%;
  height: 50vh;
  .hoc-sp-button {
    color: white;
    padding: 20px 3rem;
    cursor: pointer;
    border-radius: 3px;
    background-color: rgba(0, 0, 0, 0);
    outline: white solid 2px;
    font-family: Oswald;
    font-weight: 500;
    text-transform: uppercase;
    font-size: 17px;
    border: 0px;
    &:hover {
      background-color: white;
      color: var(--darkgreen);
    }
    margin: 30px 0px;
  }
`;

const Container = styled.div`
  padding: 40px 40px;
  text-align: 'left';
  .hoc-button {
    display: inline-block;
    padding: 20px 3rem;
  }
  @media screen and (max-width: ${MOBILE_BREAKPOINT}) {
    min-height: 420px;
    padding: 30px 100px 140px 30px;
  }
`;

const Title = styled.h3`
  margin-bottom: 30px;
  font-family: 'Oswald';
  font-style: normal;
  font-weight: 500;
  font-size: 48px;
  line-height: 100%;
  color: white;
  @media screen and (max-width: ${MOBILE_BREAKPOINT}) {
    font-size: 36px;
  }
`;
const Description = styled.div`
  width: 400px;
  margin: 0;
  margin-bottom: 30px;
  font-family: 'Lato';
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 120%;
  color: white;
  @media screen and (max-width: ${MOBILE_BREAKPOINT}) {
    width: auto;
    font-size: 14px;
  }
`;

export default function MultiArticle({ leftarticle, rightarticle }) {
  const image =
    leftarticle.BannerImage.localFile.childImageSharp.gatsbyImageData;

  return (
    <Wrapper className="container-fluid">
      <div className="row">
        <div className="col-lg-8 col-12">
          <LeftArticle>
            <BgImage
              image={image}
              preserveStackingContext
              className="bg-image"
              style={{
                width: '100%',
                height: '100%',
                backgroundPosition: 'center bottom',
                borderRadius: '3px',
                overflow: 'hidden',
                backgroundSize: 'cover',
                zIndex: 1,
              }}
            >
              <Container>
                <Title>{leftarticle.Title}</Title>
                <Description>{leftarticle.Preview_Text}</Description>
                {/*  link to article  {`/blog/${leftarticle.slug}`} */}
                <Link to="/menu" className="hoc-button">
                  read article
                </Link>
              </Container>
            </BgImage>
            <Link to="/">
              <button className="viewAllBtn-desktop hoc-button-outline">
                VIEW ALL ARTICLES
              </button>
            </Link>
          </LeftArticle>
        </div>

        <div className="col-lg-4 col-12">
          <RightArticle>
            <Container>
              <Title>{rightarticle.Title}</Title>
              {/* {`/blog/${rightarticle.slug}`} */}
              <Link to="/menu">
                <button className="hoc-sp-button">read article</button>
              </Link>
            </Container>
          </RightArticle>
          <Link to="/">
            <button className="viewAllBtn-mobile hoc-button-outline">
              VIEW ALL ARTICLES
            </button>
          </Link>
        </div>
      </div>
    </Wrapper>
  );
}
